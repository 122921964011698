<template>
    <div class="profile">
        <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
        <div class="container">
            <h2>账号设置</h2>
            <div class="profile-info">
                <div class="initials">{{ $store.state.auth.profileInitials }}</div>
                <div class="admin-badge">
                    <AdminIcon class="icon" v-if="role === 'admin'"/>
                    <user v-else class="icon" />
                    <span>{{ role === 'admin'?'管理员':'普通用户' }}</span>
                </div>
                <div class="input">
                    <label for="username">用户名:</label>
                    <input type="text" id="username" v-model="username" />
                </div>
                <div class="input">
                    <label for="email">邮箱:</label>
                    <input disabled type="text" id="email" v-model="email" />
                </div>
                <button @click="updateProfile">保存修改</button>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '../components/Modal.vue';
import AdminIcon from '../assets/Icons/user-crown-light.svg';
import user from "../assets/Icons/user-alt-light.svg";

export default {
    name:"Profile",
    data(){
      return{
        modalActive:null,
        modalMessage:"",
        username:"",
        email:"",
        role:null
      }
    },
    created(){
      this.updateUser();
    },
    watch:{
      '$store.state.auth.userName':{
        handler(newValue,oldValue){
          this.username = newValue;
          console.log(oldValue)
        }
      },
      '$store.state.auth.email':{
        handler(newValue,oldValue){
          this.email = newValue;
          console.log(oldValue)
        }
      },
      '$store.state.auth.role':{
        handler(newValue,oldValue){
          this.role = newValue;
          console.log(oldValue)
        }
      },
    },
    components:{
        Modal,
        AdminIcon,
        user
    },
    methods:{
        async updateProfile(){
          console.log(this.username)
          var that = this
          await this.$store.dispatch('updateUserName',{
            email:that.email,
            username:that.username
          })
          .then(()=>{
              this.modalActive = true;
              this.modalMessage = "更新成功!";
          })
          .catch((err)=>{
              console.log(err)
          })         
        },
        updateUser(){
          this.username = this.$store.state.auth.userName;
          this.email = this.$store.state.auth.email;
          this.role = this.$store.state.auth.role;
        },
        closeModal(){
          this.updateUser();
          this.modalActive = false;
          this.modalMessage = "";
        }
    }

}
</script>

<style lang="scss" scoped>
.profile {
  .container {
    max-width: 1000px;
    padding: 60px 25px;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }
    .profile-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;
      .initials {
        position: initial;
        width: 80px;
        height: 80px;
        font-size: 32px;
        background-color: #303030;
        color: #fff;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .admin-badge {
        display: flex;
        align-self: center;
        color: #fff;
        font-size: 14px;
        padding: 8px 24px;
        border-radius: 8px;
        background-color: #303030;
        margin: 16px 0;
        text-align: center;
        text-transform: capitalize;
        .icon {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }
      .input {
        margin: 16px 0;
        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
}
</style>